import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Legal from "./pages/Legal";
import Contact from "./pages/Contact";
import ForgotPassword from "./pages/ForgotPassword"
import Activation from "./pages/Activation";
import NewPassword from "./pages/NewPassword";
import Start from "./pages/Start";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState, useEffect } from "react";
import Profile from "./pages/Profile";
import ProfileEdit from "./pages/ProfileEdit";
import ChangePassword from "./pages/ChangePassword";
import Tickets from "./pages/Tickets";
import Records from "./pages/Records";
import ActivityDetail from "./pages/ActivityDetail";
import Ticketing from "./pages/Ticketing";
import Payment from "./pages/Payment";
import Docs from "./pages/Docs"
import ActivityReport from "./pages/ActivityReport";
import TicketControl from "./pages/TicketControl";
import ErrorPage from "./components/ErrorPage";
import AddFee from "./pages/AddFee";
import Collaboration from "./pages/Collaboration";
import ContactMessages from "./pages/ContactsMessages";
import StoreWays from "./pages/StoreWays";
import ScrollButton from "./components/ScrollButton";
import axios from "axios";
import globals from "./config/globals/globals";
import AddActivity from "./pages/AddActivity";
import Panel from "./pages/Panel";
import Activities from "./pages/Activities";
import AddSeats from "./pages/AddSeats";
import WalletTransactions from "./pages/WalletTransactions";
import DailyTransactions from "./pages/DailyTransactions";
import ActivityTransactions from "./pages/ActivityTransactions";
import Notifications from "./pages/Notifications";
import Adds from "./pages/Adds";


function App() {
    const [token, setToken] = useState('');
    const HTTP = axios.create({ baseURL: globals.apiUrl });
    const [number, setNumber] = useState();
    const [userId, setUserId] = useState("")
    const [createdId, setCreatedId] = useState("")
    const [userType, setUserType] = useState("")

    useEffect( () => {
        const getuser =  JSON.parse(localStorage.getItem("auth"));
        if (getuser?.result) {
            setToken(getuser.token);
            setUserId(getuser.user.id)
            setUserType(getuser.user.type)
            if( typeof getuser.user.email_extra === 'undefined'){
                setToken('')
                localStorage.removeItem("auth");
            }
        }   
    }, []);

    useEffect(() => {
        setNumber(window.location.href.split('/')[6])
        if (number) {
            HTTP.get(`tickets/checkQrCode/${number}`).then(res => {
                if (res.data) {
                    setCreatedId(res.data.ticket.activity.created_by)
                }
            }
            ).catch(e=>e);
        }
    }, [number]);
    
    return (
        <div>
            <BrowserRouter >    
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/i/login" element={<Login />} />
                    <Route path="/i/register" element={<Register />} />
                    <Route path="/i/forgotPassword" element={<ForgotPassword />} />
                    <Route path="/i/addFee" element={token ? <AddFee />:<Start />} />
                    <Route path="/i/activation" element={<Activation />} />
                    <Route path="/i/newPassword" element={<NewPassword />} />
                    <Route path="/i/start" element={<Start />} />
                    <Route path="/i/legal" element={<Legal />} />
                    <Route path="/i/profile" element={token ? <Profile />:<Start />} />
                    <Route path="/i/contact" element={<Contact />} />
                    <Route path="/i/profileEdit" element={token  ? <ProfileEdit /> : <Start />} />
                    <Route path="/i/notifications" element={token  ? <Notifications /> : <Start />} />
                    <Route path="/i/changePassword" element={<ChangePassword />} />
                    <Route path="/i/tickets" element={token? <Tickets />:<Start />} />
                    <Route path="/i/records" element={<Records />} />
                    <Route path="/i/payment" element={token ? <Payment /> : <Start />} />
                    <Route path={"/a/:number"} element={<ActivityDetail />} />
                    <Route path={"/a/:number/*"} element={<ActivityDetail />} />
                    <Route path={"/a/ticketing/:number"} element={token ? <Ticketing /> : <Start />} />
                    <Route path={"/a/activityreport/:number"} element={token ? <ActivityReport /> : <Start />} />
                    <Route path="/i/docs" element={<Docs/>} />
                    {/* <Route path="/api/tickets/checkQrCode/:number" element={userId==createdId?<TicketControl/>:<ErrorPage/>} /> */}
                    <Route path="/api/tickets/checkQrCode/:number" element={<TicketControl/>} />
                    <Route path="/i/collaboration" element={<Collaboration />} />
                    <Route path="/i/contactMessages" element={userType == 3 ?<ContactMessages />:<Start />} />
                    <Route path="/i/vidipass" element={<StoreWays />} />
                    <Route path="/i/addActivity" element={userType == 3 ? <AddActivity/> : <ErrorPage />} />
                    <Route path="/i/panel" element={(userType==3 || userType == 5 || userType == 2) ?<Panel/>:<ErrorPage/>} />
                    <Route path="/i/activities" element={(userType==3 || userType == 5 || userType == 2) ? <Activities/> :<ErrorPage/>} />
                    <Route path="/i/addSeats" element={userType==3?<AddSeats/>:<ErrorPage/>} />
                    <Route path="/i/adds" element={userType==3?<Adds/>:<ErrorPage/>} />
                    <Route path="/i/walletTransactions" element={userType == 3 || userType == 5 ? <WalletTransactions /> : <ErrorPage />} />
                    <Route path="/i/dailyTransactions" element={userType == 3 || userType == 5 ? <DailyTransactions /> : <ErrorPage />} />
                    <Route path="/i/activityTransactions" element={userType == 3 || userType == 5  ? <ActivityTransactions /> : <ErrorPage />} />
                    <Route path="*" element={<ErrorPage/>} />
                </Routes>
            </BrowserRouter>
            <ScrollButton/>
            <ToastContainer />
        </div>
    );
}

export default App;